/* eslint-disable jsx-a11y/anchor-is-valid */
/**
 * External Dependencies
 */
import React from 'react'
import { graphql } from 'gatsby'

/**
 * Internal Dependencies
 */
import ArchiveFeatures from '../components/ArchiveFeatures'
import ArchiveFilms from '../components/ArchiveFilms'
import Layout from '../components/Layout'
import PromoPanelWide from '../components/PromoPanelWide'
import SEO from '../components/SEO'
import TranslateText from '../components/TranslateText'
import parseContent from '../utils/parse-content'
import { filterPreviewContent } from '../utils/filter-preview-content'
import { translateListingPostUrls } from '../utils/url-translations'

const getPostData = (postArray, wpData, wpField, yamlData, yamlField) => {
  let postsToShow = []
  let fieldName = ''
  const wpHasPostIds = wpData.post.listingsPrimaryPosts
    .split(',')
    .map(id => parseInt(id.trim(), 10))
    .filter(x => x)

  if (wpHasPostIds.length) {
    // Process WP post only if the header post has been set in WP!
    postsToShow = wpData.post[wpField]
      .split(',')
      .map(id => parseInt(id.trim(), 10))
      .filter(x => x)
    fieldName = 'databaseId'
  } else {
    // Process YAML!
    postsToShow = yamlData.homepage?.posts[yamlField] || []
    if (!Array.isArray(postsToShow)) {
      postsToShow = [postsToShow]
    }
    fieldName = 'slug'
  }

  // pick out the posts we want to show
  let filteredPosts = postArray.filter(p => postsToShow.includes(p[fieldName]))

  // arrange into object so we can reference posts quickly and order by
  filteredPosts = filteredPosts.reduce((acc, p) => {
    acc[p[fieldName]] = p
    return acc
  }, {})

  // make sure they're in the order specified in WP or YAML
  const postData = postsToShow.map(field => filteredPosts[field]).filter(item => !!item)
  return postData
}

const HomeTemplate = ({ data, pageContext: { localeSettings, urlTranslations } }) => {
  // Not ideal to stick them all together like this but allWpContentNode
  // has no language filter, so we need something to work around that.
  const posts = filterPreviewContent([
    ...data.allWpExplore.nodes,
    ...data.allWpInnovate.nodes,
    ...data.allWpInspire.nodes,
    ...data.allWpDrive.nodes,
    ...data.allWpShortStory.nodes,
    ...data.allWpPromoPanel.nodes,
  ])

  let myPrimaryPosts = getPostData(posts, data, 'listingsPrimaryPosts', localeSettings, 'primary')
  myPrimaryPosts = translateListingPostUrls(myPrimaryPosts, urlTranslations.contentUrlTranslations)
  let mySecondaryPosts = getPostData(
    posts,
    data,
    'listingsSecondaryPosts',
    localeSettings,
    'secondary'
  )
  mySecondaryPosts = translateListingPostUrls(
    mySecondaryPosts,
    urlTranslations.contentUrlTranslations
  )

  const myPromoPanelPosts = getPostData(posts, data, 'listingsPromoPanel', localeSettings, 'xxx')

  return (
    <Layout isHomePage settings={localeSettings}>
      <SEO title="Mazda Stories" seo={data.post.seo} />

      {parseContent(data.post.content)}

      <div className="container alignfull">
        <div className="homepage-features--primary">
          <ArchiveFeatures posts={myPrimaryPosts} />
        </div>

        <div className="component">
          {myPromoPanelPosts.length > 0 && (
            <div>
              <PromoPanelWide posts={myPromoPanelPosts} />
            </div>
          )}
          {!!data.allWpFilm?.nodes?.length && (
            <div className="component-latest-film bg-dark alignfull">
              <div className="container">
                <h2>
                  <TranslateText name="latest_film" />
                </h2>
              </div>
              <ArchiveFilms posts={data.allWpFilm.nodes} />
            </div>
          )}
        </div>

        <div className="homepage-features--secondary">
          <ArchiveFeatures posts={mySecondaryPosts} />
        </div>
        {/* Don't think more stories are required here anymore! */}
        {/* {!!homepagePosts?.more?.length && (
          <MoreStories
            stories={(homepagePosts.more || []).map(slug =>
              posts.find(x => x.slug === slug)
            )}
          />
        )} */}
      </div>
    </Layout>
  )
}

export default HomeTemplate

/**
 * A little messy this, but allWpContentNode has no language
 * filter so we query all of the post types (nodeTypes) separately.
 */
export const pageQuery = graphql`
  query WordPressHome($locale: String!) {
    post: wpPage(slug: { eq: "homepage" }, language: { locale: { eq: $locale } }) {
      id
      databaseId
      uri
      slug
      guid
      title
      content
      listingsPrimaryPosts
      listingsSecondaryPosts
      listingsPromoPanel
      seo {
        metaDesc
        metaKeywords
        opengraphDescription
        opengraphImage {
          uri
          localFile {
            publicURL
          }
        }
        opengraphSiteName
        opengraphTitle
        opengraphType
        title
        twitterDescription
        twitterImage {
          uri
          localFile {
            publicURL
          }
        }
        twitterTitle
      }
    }
    allWpExplore(filter: { language: { locale: { eq: $locale } }, exclusive: { ne: true } }) {
      nodes {
        id
        databaseId
        excerpt
        nodeType
        slug
        title
        uri
        featuredVideo
        featuredImage {
          node {
            altText
            localFile {
              publicURL
              childImageSharp {
                fluid(quality: 90, maxWidth: 780) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        mobileFeaturedImageUrl
        tags {
          nodes {
            name
          }
        }
      }
    }
    allWpInnovate(filter: { language: { locale: { eq: $locale } }, exclusive: { ne: true } }) {
      nodes {
        id
        databaseId
        excerpt
        nodeType
        slug
        title
        uri
        featuredVideo
        featuredImage {
          node {
            altText
            localFile {
              publicURL
              childImageSharp {
                fluid(quality: 90, maxWidth: 780) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        mobileFeaturedImageUrl
        tags {
          nodes {
            name
          }
        }
      }
    }
    allWpInspire(filter: { language: { locale: { eq: $locale } }, exclusive: { ne: true } }) {
      nodes {
        id
        databaseId
        excerpt
        nodeType
        slug
        title
        uri
        featuredVideo
        featuredImage {
          node {
            altText
            localFile {
              publicURL
              childImageSharp {
                fluid(quality: 90, maxWidth: 780) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        mobileFeaturedImageUrl
        tags {
          nodes {
            name
          }
        }
      }
    }
    allWpFilm(
      filter: { language: { locale: { eq: $locale } } }
      sort: { fields: [date], order: DESC }
    ) {
      nodes {
        id
        databaseId
        excerpt
        slug
        title
        uri
        featuredVideo
        featuredVideoThumb
        featuredImage {
          node {
            altText
            localFile {
              publicURL
              childImageSharp {
                fluid(quality: 90, maxWidth: 780) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        tags {
          nodes {
            name
          }
        }
      }
    }
    allWpDrive(filter: { language: { locale: { eq: $locale } }, exclusive: { ne: true } }) {
      nodes {
        id
        databaseId
        excerpt
        nodeType
        slug
        title
        uri
        featuredVideo
        featuredImage {
          node {
            altText
            localFile {
              publicURL
              childImageSharp {
                fluid(quality: 90, maxWidth: 780) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        mobileFeaturedImageUrl
        tags {
          nodes {
            name
          }
        }
      }
    }
    allWpShortStory(filter: { language: { locale: { eq: $locale } }, exclusive: { ne: true } }) {
      nodes {
        id
        databaseId
        excerpt
        nodeType
        slug
        title
        uri
        featuredVideo
        featuredImage {
          node {
            altText
            localFile {
              publicURL
              childImageSharp {
                fluid(quality: 90, maxWidth: 780) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        mobileFeaturedImageUrl
        tags {
          nodes {
            name
          }
        }
      }
    }
    allWpPromoPanel(filter: { language: { locale: { eq: $locale } } }) {
      nodes {
        id
        databaseId
        nodeType
        slug
        title
        uri
        promoPanelTitle
        promoPanelTextColor
        promoPanelLayout
        promoPanelButton
        promoPanelButtonUrl
        promoPanelButtonNewWindow
        promoPanelBody
        promoPanelBgColor
        featuredImage {
          node {
            altText
            localFile {
              publicURL
              childImageSharp {
                fluid(quality: 90, maxWidth: 780) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        tags {
          nodes {
            name
          }
        }
      }
    }
  }
`
