/**
 * External Dependencies
 */
import React from 'react'
import classNames from 'classnames'
import PromoPanel from './PromoPanel'

const formatPromoPanelLayout = layoutName => {
  // HACK AND IS A BIT ODD
  // the wide promo panel should default to layout-2-col-wide
  // if the post is layout-2-col, it should take on a 2 column layout
  let formatted = layoutName
  if (layoutName === 'layout-2-col') {
    formatted = 'layout-3-col'
  } else {
    formatted = 'layout-2-col'
  }

  formatted += '-wide'

  return formatted
}

const is3Columns = layoutName => layoutName.includes('layout-3-col')

// eslint-disable-next-line react/function-component-definition
export default function PromoPanelWide({ posts }) {
  const post = { ...posts[0] }
  const { promoPanelTextColor, promoPanelBgColor } = post
  post.promoPanelLayout = formatPromoPanelLayout(post.promoPanelLayout)

  const cssStyles = {
    color: promoPanelTextColor,
    backgroundColor: promoPanelBgColor,
  }

  return (
    <div>
      <div className="alignfull promo-panel-wide">
        {/* Adding container class offsets the grid */}
        <div className="container">
          <div
            className={classNames(
              'promo-panel-wide-content',
              is3Columns ? 'promo-panel-wide-is-3-columns' : ''
            )}
            style={cssStyles}
          >
            <PromoPanel post={post} />
          </div>
        </div>
      </div>
    </div>
  )
}
